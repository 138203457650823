<template>
<v-container fluid class="pa-0">
   
  <v-container class="d-flex flex-wrap justify-center align-center">
    <v-col cols="12 mb-5" >
         <h1 class="page-title">Galeria</h1>
      </v-col>
      <v-col v-if="gallerys.length === 0" class="d-flex flex-column justify-center align-center" cols="12">
        <v-img src="../../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
        <h5 class="pa-0">Carregando...</h5> 
      </v-col>
    <tinybox v-if="images.length > 0" @close="tinyboxClose" v-model="tinyboxIndex" loop :images="images"/>
      <v-col cols="12"  sm="6" md="4" lg="4" xl="4" v-for="(gallery, index) in gallerys" :key="index" >
        <card-gallery :title="gallery.title" :id="gallery.id" :url="gallery.url" @open="openGallery($event)" :height="'258'" :minheight="'258'"/>
      </v-col>
  </v-container>
</v-container>
</template>

<script>
import galleryService from '../../../../service/galleryService'

export default {
  name: "Gallery",
  metaInfo: { 
    title: "SEEB | Galeria",
     meta: [
      { name: 'description', content: 'Nossos eventos, confraternizações e os melhores momentos estão registrados aqui.' }
    ]
    
  },
  methods:{
    openGallery(id){
       galleryService.get({id:id}).then(res => {
         this.images = res.data.map(img => {
           return {
             src:img.path,
             thumbnail:'',
             caption:'',
             alt:''
           }
         })
        
       })
    },
    getGallerys(){
      
      galleryService.get({}).then(res => {
       this.gallerys = res.data.map(gallery => {
              return {
                id:gallery.id,
                title:gallery.title,
                category:gallery.category,
                date:new Date(gallery.created_at).toLocaleDateString(),
                url:gallery.path
              }
          })
      })
    },
     tinyboxClose(){
    this.images = []
    this.tinyboxIndex = 0
  },
  },
 
  mounted(){
    this.getGallerys()
  },
  components:{
    Tinybox:() => import("vue-tinybox"),
    CardGallery:() => import("../../../../components/commons/CardGallery"),
    Footer: () => import("@/layouts/web/commons/Footer.vue"),
  },
  data() {
    return {
      gallerys:[],
      images:[],
      tinyboxIndex:0
    };
  }
};
</script>

<style lang="sass">
  .page-title 
    background: #114979
    color: #fff
    font-family: Raleway,sans-serif
    font-weight: 700
    line-height: 1
    margin: 0
    font-size: 1.75rem
    padding: 1.25rem 1.25rem
</style>
